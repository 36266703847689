import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, Divider, TextField, Modal } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { isMobile } from "react-device-detect";
import {
    useParams
} from "react-router-dom";
import QrCode2Icon from '@mui/icons-material/QrCode2';

const WefastBoxHeader = ({ deliveryBookingId }) => {
    return (
        <Grid container style={{ paddingBottom: 20 }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography variant='overline' style={{ textAlign: 'center', width: '100%', fontWeight: 600 }}>
                    Wallet Recharge
                </Typography>
            </Grid>
            {deliveryBookingId &&
                <>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <Typography variant='overline' >
                            {deliveryBookingId.name}
                        </Typography>
                        <Typography variant='overline' >
                            {deliveryBookingId.address}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column' }}>
                        <Typography variant='overline'>Current Balance</Typography>
                        <Typography> ₹{deliveryBookingId.wallet.balance}</Typography>
                    </Grid>
                </>}
        </Grid>
    )
}


const PaymentReceived = () => {
    return (
        <Box style={{ width: '100%', backgroundColor: '#EFFFF6', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', alignItems: 'center', padding: 60, border: '1px solid #eee' }} >
            <CheckCircleIcon style={{ fontSize: 50, color: '#50D185' }} />
            <Typography variant='overline' style={{ marginTop: 25, lineHeight: 1 }} > Payment Recevied </Typography>
            <Typography variant='overline' style={{ marginTop: 0, fontSize: 10 }} > Order ID : #123256 </Typography>
        </Box>
    )
}


const PaymentLoader = () => {
    return (
        <Box style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, border: '1px solid #eee' }} >
            <CircularProgress disableShrink />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 10 }} > Fetching Order Details </Typography>
        </Box>
    )
}


const PaymentError = ({ onReset, status, hasReset }) => {
    let message = 'Something Went Wrong';

    if (status === 'no_payment_response')
        message = 'No Payment Response Received';

    return (
        <Box style={{ backgroundColor: '#f5e6e6', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, minHeight: 200, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', border: '1px solid #eee' }} >

            <ErrorIcon style={{ fontSize: 30, color: '#B00020' }} />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 13 }} > {message} </Typography>

            <Button onClick={() => onReset()} variant='text' > Retry </Button>
        </Box>
    )
}


const SelectOrder = ({ onSelectOrder, isLoading, hasReset }) => {
    let params = useParams();
    const [orderId, setOrderId] = useState(params.mob);


    return (
        <Box style={{ padding: 20, paddingTop: 50, paddingBottom: 50, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <WefastBoxHeader />

            {isLoading && <PaymentLoader />}

            {!isLoading && <>
                <TextField label='Registered Mobile ' fullWidth variant="outlined" type="number" value={orderId} onChange={(e, text) => setOrderId(e.target.value)} ></TextField>
                <Button variant='contained' fullWidth color='primary' onClick={() => onSelectOrder(orderId)} style={{ marginTop: 15 }}> Next </Button>
            </>}


        </Box>
    )
}

const SelectRechargeAmount = ({ onSelectOrder, isLoading, hasReset, dboy }) => {
    const [orderId, setOrderId] = useState(0);


    return (
        <Box style={{ padding: 20, paddingTop: 50, paddingBottom: 50, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <WefastBoxHeader deliveryBookingId={dboy} />

            {isLoading && <PaymentLoader />}

            {!isLoading && <>
                <TextField label='Recharge amount ' fullWidth variant="outlined" type="number" value={orderId} onChange={(e, text) => setOrderId(e.target.value)} ></TextField>
                <Button variant='contained' fullWidth color='primary' onClick={() => onSelectOrder(orderId)} style={{ marginTop: 15 }}> Next </Button>
            </>}


        </Box>
    )
}


function CountDown({ onDone }) {
    const [counter, setCounter] = React.useState(250);
    // Suggested by Laurent
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        counter < 1 && onDone();
    }, [counter]);

    return (
        <Typography> Pay Within {counter}s </Typography>
    );
}
const PaymentQRBox = ({ orderId }) => {
    const [loading, setLoading] = useState(true);
    const [qrURL, setQRURL] = useState();
    const [orderTotal, setOrderTotal] = useState();

    const generateQR = () => {
        setLoading(true);
        axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/pay', {
            data: {
                orderId
            }
        }).then(({ data, error }) => {

            if (data) {
                setQRURL(data.data.qrcode);
                setOrderTotal(data.data.amount);
                setLoading(false);


            } else {
                alert('Something Went Wrong!')
            }
        })
    }
    useEffect(() => {
        generateQR();
    }, [orderId])


    return (
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', padding: 20 }}>
            {loading && <PaymentLoader />}

            {!loading && <>
                <CountDown onDone={() => generateQR()} />
                <img src={qrURL} alt='Payment Qr' style={{ height: 'auto', width: 500, maxWidth: '90%' }} />
                <Typography variant='h5' >#{orderId} &nbsp;&nbsp; ₹{orderTotal} </Typography>
            </>}
        </Box>
    )
}

const DeliveryPartnerBox = () => {
    let defaultPaymentMethod = 'razorpay_upi';
    if (!isMobile)
        defaultPaymentMethod = 'cashfree_qrcode';

    let [hasPaymentReceived, setHasPayementReceived] = useState(false);
    let [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
    let [deliveryPartnerDetails, setDeliveryPartnerDetails] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    let [orderId, setOrderId] = useState();
    let [rechargeAmount, setRechargeAmount] = useState(0);

    let [activeOrderId, setAciveOrderId] = useState();

    let [orderDetails, setOrderDetails] = useState({});
    let [step, setStep] = useState(0);

    let [status, setStatus] = useState('init');
    let [pgLink, setpgLink] = useState(false);
    let [hasReset, setHasReset] = useState(false);




    const onSelectOrder = async (orderId) => {
        setOrderId(orderId);
        setIsLoading(true);

        let orderpay = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/deliveryBoy/profile', { mobile: orderId }).catch(error => {
            console.log({ error });
        })

        if (orderpay) {

            console.log(orderpay.data);

            setStatus(orderpay.data.status);
            if (orderpay.data.status === 'success') {


                setDeliveryPartnerDetails(orderpay.data.data);
                setOrderDetails(orderpay.data.data);
                setStep(1);
            }

            setIsLoading(false);
        } else {
            // console.log({ orderpay });
            // setStatus('something_went_wrong');
            // setIsLoading(false);
        }
    }

    const onReset = () => {
        setStep(0);
        setStatus('init');
        setIsLoading(false);
        setHasReset(true);
    }

    const onSelectRechargeAmount = async (amount) => {
        setRechargeAmount(amount);
        setIsLoading(true);
        let orderpay = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/wallet/add/upi', { mobile: orderId, amount: amount }).catch(error => {
            console.log({ error });
        })

        if (orderpay) {
            setpgLink(orderpay.data.upiLink);
        }

        setStep(2);
    }
    if ((status === 'something_went_wrong' || status === 'no_payment_response') && !isLoading) {
        return <PaymentError onReset={onReset} hasReset={hasReset} />
    }

    if ((hasPaymentReceived || status === 'already_paid') && !isLoading) {
        return <PaymentReceived />
    }

    if (step === 0)
        return <SelectOrder onSelectOrder={onSelectOrder} isLoading={isLoading} status={status} hasReset={hasReset} />;

    if (step === 1)
        return <SelectRechargeAmount dboy={orderDetails} onSelectOrder={onSelectRechargeAmount} isLoading={isLoading} status={status} hasReset={hasReset} />;


    let afterRechargeAmount = parseFloat(rechargeAmount) + parseFloat(orderDetails.wallet.balance);
    return (
        <Grid container style={{ padding: 20, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <WefastBoxHeader deliveryBookingId={orderDetails} />

            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}  >
                <Typography variant='caption' style={{ textTransform: 'uppercase', fontWeight: 700, fontFamily: 'Roboto' }}  > Recharge </Typography>
                <Typography variant='h4' style={{ float: 'right' }} >
                    ₹{rechargeAmount}
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column' }}  >
                <Typography variant='caption' style={{ textTransform: 'uppercase', fontWeight: 700, fontFamily: 'Roboto' }}  > AFTER BALANCE </Typography>

                <Typography variant='h4' style={{ float: 'right' }} >
                    ₹{afterRechargeAmount}
                </Typography>
            </Grid>

            <Grid container >

                <Divider />
            </Grid>

            {pgLink && <a href={pgLink} target='_blank' style={{ width: '100%' }}>
                <Button variant='contained' fullWidth color='primary' style={{ marginTop: 20 }}> Confirm & Pay </Button>
            </a>}
        </Grid>
    )
}

export default DeliveryPartnerBox;