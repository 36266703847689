import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, Divider, TextField, Modal } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { isMobile } from "react-device-detect";
import {
    useParams
} from "react-router-dom";
import QrCode2Icon from '@mui/icons-material/QrCode2';

const WefastBoxHeader = ({ deliveryBookingId }) => {
    return (
        <Grid container style={{ paddingBottom: 20 }}>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src="https://borzodelivery.com/img/global/logo.svg" alt='Borzo Logo' style={{ width: 100, height: 50 }} />

            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column' }}>
                <Typography variant='overline'> Order ID</Typography>
                <Typography> #{deliveryBookingId}</Typography>
            </Grid>
        </Grid>
    )
}


const PaymentReceived = () => {
    return (
        <Box style={{ width: '100%', backgroundColor: '#EFFFF6', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', alignItems: 'center', padding: 60, border: '1px solid #eee' }} >
            <CheckCircleIcon style={{ fontSize: 50, color: '#50D185' }} />
            <Typography variant='overline' style={{ marginTop: 25, lineHeight: 1 }} > Payment Recevied </Typography>
            <Typography variant='overline' style={{ marginTop: 0, fontSize: 10 }} > Order ID : #123256 </Typography>
        </Box>
    )
}

const PaymentInProgress = () => {
    return (
        <Box style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 60, border: '1px solid #eee' }} >
            <CircularProgress disableShrink />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 10 }} > Please Complete The Payment </Typography>
        </Box>
    )
}

const PaymentLoader = () => {
    return (
        <Box style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, border: '1px solid #eee' }} >
            <CircularProgress disableShrink />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 10 }} > Fetching Order Details </Typography>
        </Box>
    )
}


const PaymentError = ({ onReset, status, hasReset }) => {
    let message = 'Something Went Wrong';

    if (status === 'no_payment_response')
        message = 'No Payment Response Received';

    return (
        <Box style={{ backgroundColor: '#f5e6e6', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, minHeight: 200, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', border: '1px solid #eee' }} >

            <ErrorIcon style={{ fontSize: 30, color: '#B00020' }} />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 13 }} > {message} </Typography>

            <Button onClick={() => onReset()} variant='text' > Retry </Button>
        </Box>
    )
}


const SelectOrder = ({ onSelectOrder, isLoading, hasReset }) => {
    let params = useParams();
    const [orderId, setOrderId] = useState(params.groupId);

    useEffect(() => {

        if (orderId) {
            if (orderId.length === 6 && !hasReset)
                onSelectOrder(orderId);
        }
    }, [orderId]);

    return (
        <Box style={{ padding: 20, paddingTop: 50, paddingBottom: 50, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <WefastBoxHeader />

            {isLoading && <PaymentLoader />}

            {!isLoading && <>
                <TextField label='Order Id' fullWidth variant="outlined" type="number" value={orderId} onChange={(e, text) => setOrderId(e.target.value)} ></TextField>
                <Button variant='contained' fullWidth color='primary' onClick={() => onSelectOrder(orderId)} style={{ marginTop: 15 }}> Next </Button>
            </>}


        </Box>
    )
}


const OrderItem = ({ order, onShowPaymentQrCode }) => {
    return (
        <Grid container style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '2px solid #eee' }} >
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant='overline' style={{ lineHeight: 1.5 }}>#{order.id} <br />{order.name}</Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant='overline' >{order.paymentMethod == 'COD' && `₹${order.total} - `} {order.paymentMethod}</Typography>
                {(order.paymentMethod == 'COD' && !order.paymentReceived) && <QrCode2Icon onClick={() => onShowPaymentQrCode(order.id)} />}
                {(order.paymentMethod == 'COD' && order.paymentReceived) && <CheckCircleIcon style={{ color: '#50D185' }} />}
            </Grid>
        </Grid>
    )
}

function CountDown({ onDone }) {
    const [counter, setCounter] = React.useState(250);
    // Suggested by Laurent
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

        counter < 1 && onDone();
    }, [counter]);

    return (
        <Typography> Pay Within {counter}s </Typography>
    );
}
const PaymentQRBox = ({ orderId }) => {
    const [loading, setLoading] = useState(true);
    const [qrURL, setQRURL] = useState();
    const [orderTotal, setOrderTotal] = useState();

    const generateQR = () => {
        setLoading(true);
        axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/pay', {
            data: {
                orderId
            }
        }).then(({ data, error }) => {

            if (data) {
                setQRURL(data.data.qrcode);
                setOrderTotal(data.data.amount);
                setLoading(false);


            } else {
                alert('Something Went Wrong!')
            }
        })
    }
    useEffect(() => {
        generateQR();
    }, [orderId])


    return (
        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', padding: 20 }}>
            {loading && <PaymentLoader />}

            {!loading && <>
                <CountDown onDone={() => generateQR()} />
                <img src={qrURL} alt='Payment Qr' style={{ height: 'auto', width: 500, maxWidth: '90%' }} />
                <Typography variant='h5' >#{orderId} &nbsp;&nbsp; ₹{orderTotal} </Typography>
            </>}
        </Box>
    )
}

const WefastBox = () => {
    let defaultPaymentMethod = 'razorpay_upi';
    if (!isMobile)
        defaultPaymentMethod = 'cashfree_qrcode';

    let [hasPaymentReceived, setHasPayementReceived] = useState(false);
    let [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
    let [isLoading, setIsLoading] = useState(false);
    let [orderId, setOrderId] = useState();
    let [activeOrderId, setAciveOrderId] = useState();
    let [orderDetails, setOrderDetails] = useState({});
    let [step, setStep] = useState(0);
    let [status, setStatus] = useState('init');
    let [showQR, setShowQR] = useState(false);
    let [hasReset, setHasReset] = useState(false);


    const keepCheckingForPaymentStatus = async () => {
        const invtervalinit = setInterval(async () => {
            let order_payment = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/order/payment-status', { data: { orderId: activeOrderId } }).catch(error => {
                console.log({ error });
            })

            console.log({ order_payment });
            if (order_payment) {

                if (order_payment.data.payment_received) {
                    onSelectOrder(orderId);
                    clearInterval(invtervalinit);
                }

            }
        }, 1000);

    }



    const onSelectOrder = async (orderId) => {
        setOrderId(orderId);
        setIsLoading(true);

        let orderpay = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/payments/group', { data: { groupId: orderId } }).catch(error => {
            console.log({ error });
        })

        if (orderpay) {

            setStatus(orderpay.data.status);
            if (orderpay.data.status === 'already_paid') {
                setHasPayementReceived(true);
            } else {
                setOrderDetails(orderpay.data);
                setStep(1);
            }

            setIsLoading(false);
        } else {
            console.log({ orderpay });
            setStatus('something_went_wrong');
            setIsLoading(false);
        }
    }

    const onShowPaymentQrCode = (orderId) => {
        setShowQR(true);
        setAciveOrderId(orderId);
        keepCheckingForPaymentStatus();
    }
    const onReset = () => {
        setStep(0);
        setStatus('init');
        setIsLoading(false);
        setHasReset(true);
    }
    if ((status === 'something_went_wrong' || status === 'no_payment_response') && !isLoading) {
        return <PaymentError onReset={onReset} hasReset={hasReset} />
    }

    if ((hasPaymentReceived || status === 'already_paid') && !isLoading) {
        return <PaymentReceived />
    }

    if (step === 0)
        return <SelectOrder onSelectOrder={onSelectOrder} isLoading={isLoading} status={status} hasReset={hasReset} />;


    return (
        <Grid container style={{ padding: 20, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <WefastBoxHeader deliveryVia={orderDetails.deliveryVia} deliveryBookingId={orderDetails.deliveryBookingId} />
            <Modal open={showQR} onClose={() => setShowQR(false)} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', padding: 20 }} >
                {showQR && <PaymentQRBox orderId={activeOrderId} />}
            </Modal>
            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}  >
                <Typography variant='button' > #{orderId} </Typography>
                <Typography variant='caption' style={{ textTransform: 'uppercase', fontWeight: 700, fontFamily: 'Roboto' }}  > Total Payable </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}  >
                <Typography variant='h4' style={{ float: 'right' }} >
                    ₹{orderDetails.codTotal}
                </Typography>
            </Grid>

            <Grid container >

                <Divider />
            </Grid>
            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}  >
                    <Typography variant='overline' > Delivery Partner </Typography>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }}  >
                    <Typography variant='overline'  >
                        {orderDetails.deliveryBoyName}
                    </Typography>
                    <Typography variant='overline' style={{ position: 'relative', top: -10 }}  >
                        ({orderDetails.deliveryBoyMobile})
                    </Typography>
                </Grid>
            </Grid>
            {orderDetails.orders.map(order => <OrderItem order={order} onShowPaymentQrCode={onShowPaymentQrCode} />)}
            {orderDetails.paymentLink && <a href={orderDetails.paymentLink} target='_blank' style={{ width: '100%' }}>
                <Button variant='contained' fullWidth color='primary' style={{ marginTop: 20 }}> Pay &nbsp;&nbsp; ₹{orderDetails.codTotal} </Button>
            </a>}
        </Grid>
    )
}

export default WefastBox;