import './App.css';
import { Grid, Box, Typography } from '@material-ui/core';
import PaymentBox from './components/PaymmentBox';
import WefastBox from './components/wefastBox';
import DeliveryPartnerBox from './components/DeliveryPartnerBox';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Box style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
        <Grid container style={{ maxWidth: 800 }} >
          <Grid item xs={12} md={6}>
            <img src='https://i0.wp.com/patilkaki.com/wp-content/uploads/2020/10/pk-logo-min.png' alt='Logo' style={{ width: 200 }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Switch>
              <Route path="/partner/:mob">
                <DeliveryPartnerBox />
              </Route>
              <Route path="/partner/">
                <DeliveryPartnerBox />
              </Route>
              <Route path="/group/:groupId">
                <WefastBox />
              </Route>
              <Route path="/:orderId">
                <PaymentBox />
              </Route>

              <Route path="/">
                <PaymentBox />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </Router>
  );
}

export default App;
