import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, Divider, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import { isMobile } from "react-device-detect";
import {
    useParams
} from "react-router-dom";

const PaymentMethod = ({ pg }) => {
    let paymentDetails = [];
    paymentDetails['razorpay_upi'] = {
        title: 'UPI',
        description: 'Google Pay, Paytm, PhonePe & Other UPI Apps'
    };

    paymentDetails['razorpay'] = {
        title: 'Razorpay',
        description: 'Cards, NetBanking & UPI'
    }
    paymentDetails['cashfree_qrcode'] = {
        title: 'UPI QR Code',
        description: 'Scan & Pay Via Google Pay, Paytm, PhonePe, etc'
    }
    return (
        <Grid container style={{ paddingTop: 15 }} >
            <Grid item xs={12}>
                <Typography variant='caption' style={{ fontWeight: 900 }}  >
                    {paymentDetails[pg].title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='caption' >
                    {paymentDetails[pg].description}
                </Typography>
            </Grid>
        </Grid>)
}

const PaymentReceived = () => {
    return (
        <Box style={{ width: '100%', backgroundColor: '#EFFFF6', display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', alignItems: 'center', padding: 60, border: '1px solid #eee' }} >
            <CheckCircleIcon style={{ fontSize: 50, color: '#50D185' }} />
            <Typography variant='overline' style={{ marginTop: 25, lineHeight: 1 }} > Payment Recevied </Typography>
            <Typography variant='overline' style={{ marginTop: 0, fontSize: 10 }} > Order ID : #123256 </Typography>
        </Box>
    )
}

const PaymentInProgress = () => {
    return (
        <Box style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 60, border: '1px solid #eee' }} >
            <CircularProgress disableShrink />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 10 }} > Please Complete The Payment </Typography>
        </Box>
    )
}

const PaymentLoader = () => {
    return (
        <Box style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, border: '1px solid #eee' }} >
            <CircularProgress disableShrink />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 10 }} > Fetching Order Details </Typography>
        </Box>
    )
}


const PaymentError = ({ onReset, status, hasReset }) => {
    let message = 'Something Went Wrong';

    if (status === 'no_payment_response')
        message = 'No Payment Response Received';

    return (
        <Box style={{ backgroundColor: '#f5e6e6', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, minHeight: 200, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', border: '1px solid #eee' }} >

            <ErrorIcon style={{ fontSize: 30, color: '#B00020' }} />
            <Typography variant='overline' style={{ marginTop: 15, fontSize: 13 }} > {message} </Typography>

            <Button onClick={() => onReset()} variant='text' > Retry </Button>
        </Box>
    )
}


const SelectOrder = ({ onSelectOrder, isLoading, hasReset }) => {
    let params = useParams();
    const [orderId, setOrderId] = useState(params.orderId);

    useEffect(() => {

        if (orderId) {
            if (orderId.length === 6 && !hasReset)
                onSelectOrder(orderId);
        }
    }, [orderId]);

    return (
        <Box style={{ padding: 20, paddingTop: 50, paddingBottom: 50, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            {isLoading && <PaymentLoader />}

            {!isLoading && <>
                <TextField label='Order Id' fullWidth variant="outlined" type="number" value={orderId} onChange={(e, text) => setOrderId(e.target.value)} ></TextField>
                <Button variant='contained' fullWidth color='primary' onClick={() => onSelectOrder(orderId)} style={{ marginTop: 15 }}> Next </Button>
            </>}


        </Box>
    )
}

const PaymentBox = () => {
    let defaultPaymentMethod = 'razorpay_upi';
    if (!isMobile)
        defaultPaymentMethod = 'cashfree_qrcode';

    let [hasPaymentReceived, setHasPayementReceived] = useState(false);
    let [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
    let [isLoading, setIsLoading] = useState(false);
    let [orderId, setOrderId] = useState();
    let [orderDetails, setOrderDetails] = useState({});
    let [step, setStep] = useState(0);
    let [status, setStatus] = useState('init');
    let [showQR, setShowQR] = useState(false);
    let [hasReset, setHasReset] = useState(false);


    const keepCheckingForPaymentStatus = async () => {
        const invtervalinit = setInterval(async () => {
            let order_payment = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/order/payment-status', { data: { orderId: orderId } }).catch(error => {
                console.log({ error });
            })

            console.log({ order_payment });
            if (order_payment) {

                if (order_payment.data.payment_received) {
                    setHasPayementReceived(true);
                    clearInterval(invtervalinit);
                }

            }
        }, 1000);

        setTimeout(() => {
            if (!hasPaymentReceived) {
                setStatus('no_payment_response');
                setIsLoading(false);
            }
        }, 30000);

    }

    const onProceedToPay = () => {

        if (paymentMethod === 'razorpay') {
            var options = {
                "key": "rzp_live_NdCRHddb3OJcbX", // Enter the Key ID generated from the Dashboard
                "currency": "INR",
                "name": "PatilKaki",
                "description": `Payment for Order #${orderId}`,
                "order_id": `${orderDetails.data.razorpay_order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "ondismiss": function (response) {
                    alert('dismissed');
                },
                "handler": function (response) {
                    alert(response.razorpay_payment_id);
                    alert(response.razorpay_order_id);
                    alert(response.razorpay_signature)
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on('payment.failed', function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
            });
            rzp1.open();
        }

        if (paymentMethod === 'cashfree_qrcode') {
            setShowQR(true);
        }
        keepCheckingForPaymentStatus();
        setIsLoading(true);
        // setHasPayementReceived(true);
    }

    const onSelectOrder = async (orderId) => {
        setOrderId(orderId);
        setIsLoading(true);

        let orderpay = await axios.post('https://pok-backend-y3hbx.ondigitalocean.app/api/v1/orders/pay', { data: { orderId: orderId } }).catch(error => {
            console.log({ error });
        })

        if (orderpay) {


            setStatus(orderpay.data.status);
            if (orderpay.data.status === 'already_paid') {
                setHasPayementReceived(true);
            } else {
                setOrderDetails(orderpay.data);
                setStep(1);
            }

            setIsLoading(false);
        } else {
            console.log({ orderpay });
            setStatus('something_went_wrong');
            setIsLoading(false);
        }
    }

    const onReset = () => {
        setStep(0);
        setStatus('init');
        setIsLoading(false);
        setHasReset(true);
    }
    if ((status === 'something_went_wrong' || status === 'no_payment_response') && !isLoading) {
        return <PaymentError onReset={onReset} hasReset={hasReset} />
    }

    if ((hasPaymentReceived || status === 'already_paid') && !isLoading) {
        return <PaymentReceived />
    }

    if (step === 0)
        return <SelectOrder onSelectOrder={onSelectOrder} isLoading={isLoading} status={status} hasReset={hasReset} />;


    return (
        <Grid container style={{ padding: 20, backgroundColor: '#fff', borderRadius: 10, border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }} >
            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}  >
                <Typography variant='button' > #{orderId} </Typography>
                <Typography variant='caption' style={{ textTransform: 'uppercase', fontWeight: 700, fontFamily: 'Roboto' }}  > Total Payable </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}  >
                <Typography variant='h4' style={{ float: 'right' }} >
                    ₹{orderDetails.data.amount}
                </Typography>
            </Grid>

            <Grid item xs={12}  >
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}   >
                {showQR && <img src={orderDetails.data.qrcode} style={{ width: '80%', height: 'auto' }} />}
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}   >
                {isLoading && <PaymentInProgress />}
            </Grid>
            {(!hasPaymentReceived && !isLoading) && <><Grid item xs={12}  >
                <Typography variant='overline' > Pay Via </Typography>
                <RadioGroup aria-label="Payment Method" name="paymentMethod" value={paymentMethod} onChange={(e, val) => setPaymentMethod(val)}  >
                    {isMobile && <FormControlLabel value="razorpay_upi" control={<Radio />} label={<PaymentMethod pg="razorpay_upi" />} />}
                    {!isMobile && <FormControlLabel value="cashfree_qrcode" control={<Radio />} label={<PaymentMethod pg="cashfree_qrcode" />} />}
                    <FormControlLabel value="razorpay" control={<Radio />} label={<PaymentMethod pg="razorpay" />} />
                </RadioGroup>
            </Grid>
                <Grid item xs={12} style={{ paddingTop: 30 }}  >
                    {(paymentMethod === 'razorpay' || paymentMethod === 'cashfree_qrcode') &&
                        <Button variant='contained' fullWidth color="primary" onClick={() => onProceedToPay()} > Proceed To Pay </Button>
                    }
                    {paymentMethod === 'razorpay_upi' &&
                        <a href={`${orderDetails.data.link}`} target="_blank" >
                            <Button variant='contained' fullWidth color="primary" onClick={() => onProceedToPay()} > Proceed To Pay </Button>
                        </a>
                    }
                </Grid>
            </>}
        </Grid>
    )
}

export default PaymentBox;